
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  setup: () => {
    const store = useStore()

    return {
      colors: computed(() => store.state.userInput.token.iconColors),
    }
  },
})
