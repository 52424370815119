
import { defineComponent } from 'vue'
import { NText } from 'naive-ui'

export default defineComponent({
  components: {
    NText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
})
