
import { computed, defineComponent, h } from 'vue'
import { NModal, NCard, NText, NButton, useNotification } from 'naive-ui'
import { NetworkMetadata } from '@/config/types'
import { useStore } from '@/store'
import NotificationError from '@/components/NotificationError.vue'

export default defineComponent({
  emits: ['hide'],

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    isSelectingDestination: Boolean,
  },

  components: {
    NModal,
    NCard,
    NText,
    NButton,
  },

  setup(props) {
    const store = useStore()
    const notification = useNotification()

    return {
      networks: computed(() => store.getters.activeNetworks()),
      title: computed(() => {
        return props.isSelectingDestination
          ? 'SELECT DESTINATION'
          : 'SELECT ORIGIN'
      }),
      store,
      notification,
    }
  },

  computed: {
    otherNetwork() {
      const { originNetwork, destinationNetwork } = this.store.state.userInput
      return this.isSelectingDestination ? originNetwork : destinationNetwork
    },
  },

  methods: {
    async select(network: NetworkMetadata) {
      const isUnavailable = this.unavailable(network)
      if (this.isSelectingDestination) {
        if (isUnavailable) {
          this.store.dispatch('setOriginNetwork', null)
        }
        this.store.dispatch('setDestinationNetwork', network.name)
      } else {
        try {
          await this.store.dispatch('switchNetwork', network.name)
          // set destination network to null if switch network was successful
          if (isUnavailable) {
            this.store.dispatch('setDestinationNetwork', null)
          }
        } catch (e) {
          // TODO: provide a better error message when user rejects the network change (code: 4001)
          this.notification.warning({
            title: 'Error switching network',
            content: () =>
              h(NotificationError, {
                text: 'Try adding or changing the network manually from your wallet',
                error: e as Error,
              }),
          })
          // close select origin modal if there was an error switching network
          this.$emit('hide')
          throw e
        }
      }
      this.$emit('hide')
    },
    unavailable(network: NetworkMetadata): boolean {
      if (!this.otherNetwork) return false
      if (network.name === this.otherNetwork) return true

      const { connections } = this.networks.find((n: NetworkMetadata) => {
        return n.name === this.otherNetwork
      })
      return !connections.includes(network.name)
    },
  },
})
