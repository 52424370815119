import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-lg w-full relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumb = _resolveComponent("breadcrumb")!
  const _component_tx_details = _resolveComponent("tx-details")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_breadcrumb, {
      label: "Bridge",
      class: "mb-10"
    }),
    _createVNode(_component_tx_details)
  ]))
}