
import { defineComponent, computed } from 'vue'
import { NText, NDivider, NButton } from 'naive-ui'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

import { useStore } from '@/store'
import { truncateAddr, isValidAddress } from '@/utils'
import { networks } from '@/config'
import { NetworkName } from '@/config/types'
import NetworkSelect from './Input.networks.vue'
import EditRecipient from './Input.recipient.vue'

interface ComponentData {
  showSelectOriginNetwork: boolean
  showSelectDestinationNetwork: boolean
  showEditRecipient: boolean
  truncateAddr: (addr: string) => string
}

export default defineComponent({
  components: {
    NText,
    NDivider,
    NButton,
    NetworkSelect,
    EditRecipient,
  },
  data() {
    return {
      showSelectOriginNetwork: false,
      showSelectDestinationNetwork: false,
      showEditRecipient: false,
      truncateAddr,
    } as ComponentData
  },
  setup: () => {
    const store = useStore()
    const v$ = useVuelidate({ $scope: 'bridge' })

    return {
      originAddr: computed(() => store.state.wallet.address),
      destinationAddr: computed(() => store.state.userInput.destinationAddress),
      originNetwork: computed(() => store.state.userInput.originNetwork),
      destinationNetwork: computed(
        () => store.state.userInput.destinationNetwork
      ),
      store,
      v$,
    }
  },
  validations() {
    return {
      originNetwork: { required, $lazy: true },
      destinationNetwork: {
        required: helpers.withMessage(
          '* required',
          () => !!this.destinationNetwork
        ),
        noToken: helpers.withMessage('* invalid', () => {
          const { connections } = networks[this.originNetwork]
          return connections.includes(this.destinationNetwork as NetworkName)
        }),
        $lazy: true,
      },
      destinationAddr: {
        required,
        isValid: (value: string) => isValidAddress(value),
        $lazy: true,
      },
    }
  },
  methods: {
    getDisplayName(name: NetworkName) {
      if (!name) return
      return networks[name].displayName
    },
  },
})
