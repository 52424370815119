
import { defineComponent, computed } from 'vue'
import { NText, NTooltip, NInput } from 'naive-ui'
import { utils, BigNumber } from 'ethers'
import useVuelidate from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'

import { useStore } from '@/store'
import { getMinAmount, toDecimals, toFixedDecimals } from '@/utils'
import { TokenMetadata } from '@/config/types'
import TokenSelect from './Input.tokens.vue'

interface ComponentData {
  amt: string
  showTokenSelect: boolean
  amtInUSD: string
  toDecimals: (
    amnt: BigNumber,
    tokenDecimals: number,
    numDecimals?: number
  ) => string
  toFixedDecimals: (amnt: string, numDecimals?: number) => string
}

export default defineComponent({
  components: {
    NText,
    NTooltip,
    NInput,
    TokenSelect,
  },
  data() {
    return {
      showTokenSelect: false,
      amt: '',
      amtInUSD: '',
      toDecimals,
      toFixedDecimals,
    } as ComponentData
  },
  setup() {
    const store = useStore()
    const v$ = useVuelidate({ $scope: 'bridge' })

    return {
      token: computed(() => store.state.userInput.token),
      originNetwork: computed(() => store.state.userInput.originNetwork),
      balance: computed(() => store.state.sdk.balance),
      store,
      v$,
    }
  },
  validations() {
    return {
      amt: {
        required: helpers.withMessage('Enter an amount to bridge', () => {
          if (!this.amt) return false
          return Number.parseFloat(this.amt) > 0
        }),
        noToken: helpers.withMessage(
          'No token selected',
          () => !!this.token.symbol
        ),
        noFunds: helpers.withMessage(
          'No funds',
          // if token and balance exist and balance is equal to zero
          () => {
            if (!this.token.symbol) return true
            if (!this.balance) return true
            return !this.balance.isZero()
          }
        ),
        sufficientFunds: helpers.withMessage(
          'Amount exceeds balance',
          (value: number) => {
            // only show this error if the balance is not zero since we'll already show the no funds message
            if (
              this.balance &&
              !this.balance.isZero() &&
              this.amt &&
              this.token.symbol
            ) {
              const amtBN = utils.parseUnits(
                value.toString(),
                this.token.decimals
              )
              return amtBN.lte(this.balance)
            }
            return true
          }
        ),
      },
      token: {
        required: (value: TokenMetadata) => !!value.symbol,
        $lazy: true,
      },
    }
  },
  methods: {
    selectToken(token: TokenMetadata) {
      this.store.dispatch('setToken', token)
      this.showTokenSelect = false
      this.updateAmtInUSD(token.coinGeckoId)

      // reset form errors after selecting a new token
      this.v$.$reset()
    },
    async updateAmtInUSD(coinGeckoId: string) {
      if (!this.amt) {
        this.amtInUSD = ''
        return
      }
      const amtInUSD =
        (await getMinAmount(coinGeckoId)) * Number.parseFloat(this.amt)
      this.amtInUSD = amtInUSD.toFixed(2).toString()
    },
    max() {
      if (!this.balance || !this.token.symbol) return
      const formattedBalance = toDecimals(this.balance, this.token.decimals)
      this.amt = formattedBalance

      const input = this.$refs.amount as typeof NInput
      input.inputMirrorElRef.innerHTML = formattedBalance
    },
  },
  watch: {
    token(newToken) {
      // update the usd amt if the token if changed outside of this component
      this.updateAmtInUSD(newToken.coinGeckoId)
    },
    async amt(newAmt) {
      const formattedAmt = toFixedDecimals(newAmt, 6)
      this.store.dispatch('setSendAmount', formattedAmt || 0)
      if (this.token.coinGeckoId) {
        // TODO: we might want to debounce this function depending on performance
        await this.updateAmtInUSD(this.token.coinGeckoId)
      }
    },
  },
})
