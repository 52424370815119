
import { defineComponent } from 'vue'
import { NIcon } from 'naive-ui'
import {
  HourglassOutline,
  ArrowRedoOutline,
  CloseOutline,
} from '@vicons/ionicons5'
import CopyHash from '@/components/CopyHash.vue'

export default defineComponent({
  props: {
    status: String,
    hash: String,
    expired: Boolean,
  },
  components: {
    NIcon,
    CopyHash,
    HourglassOutline,
    ArrowRedoOutline,
    CloseOutline,
  },
  computed: {
    ready() {
      return this.status === 'ReceiverTransactionPrepared'
    },
  },
})
