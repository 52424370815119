
import { defineComponent } from 'vue'
import { NText } from 'naive-ui'
import { getNetworkByChainID } from '@/utils'

export default defineComponent({
  props: {
    originChainId: {
      type: Number,
      required: true,
    },
    destinationChainId: {
      type: Number,
      required: true,
    },
  },
  components: {
    NText,
  },
  computed: {
    originName() {
      const network = getNetworkByChainID(this.originChainId)
      return network ? network.name : this.originChainId
    },
    destinationName() {
      const network = getNetworkByChainID(this.destinationChainId)
      return network ? network.name : this.destinationChainId
    },
  },
})
