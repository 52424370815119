
import { defineComponent, h } from 'vue'
import { utils, BigNumber } from 'ethers'
import { TokenIdentifier, TransferMessage } from '@nomad-xyz/sdk-bridge'
import { NText, NDivider, NTime, useNotification } from 'naive-ui'
import { useStore } from '@/store'
import { fromBytes32, toNetworkName, truncateAddr } from '@/utils'
import { nomadAPI, networks } from '@/config'
import Detail from '@/views/Transaction/Detail.vue'
import CopyHash from '@/components/CopyHash.vue'
import StatusHeader from './Header.vue'
import NotificationError from '@/components/NotificationError.vue'
import { NetworkName } from '@/config/types'
import { getTx } from '@/utils/nomadAPI'

interface ComponentData {
  transferMessage: TransferMessage | null
  status: number | undefined
  confirmAt: BigNumber | null
  amount: string
  tokenSymbol: string
  tokenId: TokenIdentifier | undefined
  originNet: NetworkName
  destNet: NetworkName
  originAddr: string
  destAddr: string
  timeSent: number | undefined
  nullVal: string
  truncateAddr: (addr: string) => string
}

export default defineComponent({
  components: {
    StatusHeader,
    Detail,
    NText,
    NDivider,
    NTime,
    CopyHash,
  },

  setup: () => {
    const store = useStore()
    const notification = useNotification()
    return {
      notification,
      store,
    }
  },

  data() {
    return {
      transferMessage: null,
      status: undefined,
      confirmAt: null,
      amount: '',
      tokenSymbol: '',
      tokenId: undefined,
      originNet: '',
      destNet: '',
      originAddr: '',
      destAddr: '',
      timeSent: undefined,
      nullVal: '—',
      truncateAddr,
    } as ComponentData
  },

  async mounted() {
    const { network, id } = this.$route.params
    this.checkUrlParams(network as string, id as string)
    this.originNet = toNetworkName(network as string)
    const txData = {
      network: this.originNet,
      hash: id,
    }
    const message = await this.store.getters.getTxMessage(txData)
    this.transferMessage = message
    console.log('transaction:\n', message)
    if (!message) {
      this.notification.error({
        title: 'Invalid URL',
        description:
          'Please check that the url has the correct network and transaction ID',
      })
      throw new Error('Unable to fetch transaction details')
    }

    // destination network
    this.destNet = this.store.getters.resolveDomainName(message.destination)
    // destination/origin addr
    this.originAddr = message.receipt.from
    this.destAddr = fromBytes32(message.to)
    // get token
    this.tokenId = message.token
    const token = await this.store.getters.resolveRepresentation(
      message.origin,
      message.token
    )
    if (token) {
      try {
        // token symbol
        this.tokenSymbol = await token.symbol()
        // amount divided by decimals
        const amountBN = message.amount.toString()
        const tokenDecimals = await token.decimals()
        this.amount = await utils.formatUnits(amountBN, tokenDecimals)
      } catch (e) {
        console.log(e)
      }
    }

    // status
    try {
      await this.updateStatus()
    } catch (e) {
      console.error(e)
    }

    setInterval(async () => {
      if (!this.status || this.status < 4) {
        await this.updateStatus()
      }
    }, 60000)
  },

  methods: {
    checkUrlParams(network: string, id: string) {
      if (!network || !id) {
        this.notification.error({
          title: 'Incomplete URL',
          description:
            'Please add the origin network and ID of your transaction',
        })
        throw new Error(
          "Incomplete transaction URL, can't fetch transaction details"
        )
      }
      if (id.length !== 66) {
        this.notification.error({
          title: 'Invalid Transaction',
          description: 'Please check that you have the correct transaction ID',
        })
        throw new Error(
          "Invalid transaction ID, can't fetch transaction details"
        )
      }
      try {
        toNetworkName(network as string)
      } catch (e) {
        this.notification.error({
          title: 'Invalid Network Name',
          description: 'Please check that you have the correct network',
        })
        throw new Error(
          "Invalid network param, can't fetch transaction details"
        )
      }
    },
    async addToken() {
      const payload = {
        network: this.destNet,
        tokenId: this.tokenId,
      }
      try {
        await this.store.dispatch('addToken', payload)
      } catch (error: unknown) {
        let text = ''
        if (this.tokenId) {
          const tokenAddr = fromBytes32(this.tokenId!.id as string)
          text = `Please try adding this token manually: ${tokenAddr}`
        }
        this.notification.warning({
          title: 'Error adding token to your wallet',
          content: () =>
            h(NotificationError, {
              text,
              error: error as Error,
            }),
        })
        throw error
      }
    },
    async updateStatus() {
      const { id } = this.$route.params
      const { optimisticSeconds } = networks[this.originNet]

      // fetch tx
      const tx = await getTx(id as string)
      console.log('tx data: ', tx)

      if (!tx) {
        return (this.status = -1)
      }

      if (tx.dispatchedAt > 0) {
        this.timeSent = tx.dispatchedAt * 1000
      }

      if (tx.state === 2) {
        if (tx.relayedAt && tx.relayedAt > 0) {
          // calculate confirmation time (in case confirmAt check errors out)
          this.confirmAt = BigNumber.from(tx.relayedAt + optimisticSeconds)
        }
      }
      // set status after we have confirmAt
      this.status = tx.state
    },
  },

  computed: {
    explorerLink(): string {
      if (!this.originNet) return ''
      const n = networks[this.originNet]
      return `${n.blockExplorer}/tx/${this.$route.params.id}`
    },
  },
})
