
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['input'],
  data: () => ({ text: null }),
  watch: {
    text(newText) {
      this.$emit('input', newText)
    },
  },
})
