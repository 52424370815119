import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_transfer_input = _resolveComponent("transfer-input")!
  const _component_transfer_review = _resolveComponent("transfer-review")!
  const _component_nomad_history = _resolveComponent("nomad-history")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.step === 1)
      ? (_openBlock(), _createBlock(_component_transfer_input, { key: 0 }))
      : _createCommentVNode("v-if", true),
    (_ctx.step === 2)
      ? (_openBlock(), _createBlock(_component_transfer_review, { key: 1 }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_nomad_history)
  ]))
}