
import { defineComponent, computed } from 'vue'
import {
  NModal,
  NCard,
  NText,
  NButton,
  NIcon,
  NTooltip,
  useNotification,
} from 'naive-ui'
import { InformationCircleOutline, CheckmarkCircle } from '@vicons/ionicons5'

import { useStore } from '@/store'
import { truncateAddr, isValidAddress, pasteFromClipboard } from '@/utils'

export default defineComponent({
  emits: ['hide'],

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    NModal,
    NCard,
    NText,
    NButton,
    NIcon,
    NTooltip,
    InformationCircleOutline,
    CheckmarkCircle,
  },

  data() {
    return {
      recipientInput: '',
      newAddress: '',
      allowEdit: false,
      allowPaste: navigator.clipboard && navigator.clipboard.readText,
      truncateAddr,
    }
  },

  setup: () => {
    const store = useStore()
    const notification = useNotification()

    return {
      userAddress: computed(() => store.state.wallet.address),
      destAddress: computed(() => store.state.userInput.destinationAddress),
      store,
      notification,
    }
  },

  methods: {
    close() {
      this.$emit('hide')
      setTimeout(() => {
        this.newAddress = ''
        this.recipientInput = ''
        this.allowEdit = false
      }, 500)
    },

    saveAddr() {
      if (this.newAddress && isValidAddress(this.newAddress)) {
        this.store.dispatch('setDestinationAddress', this.newAddress)
      }
      this.close()
    },

    async handlePaste() {
      let text = await pasteFromClipboard()
      if (isValidAddress(text)) {
        console.log('got valid address: ', text)
        this.newAddress = text
        this.allowEdit = false
      } else {
        this.notification.warning({
          title: 'Invalid Input',
          content: 'Please use a valid address',
          duration: 3000,
        })
      }
    },

    async checkAddr() {
      if (this.recipientInput.length === 42) {
        if (isValidAddress(this.recipientInput)) {
          console.log('got valid address: ', this.recipientInput)
          this.newAddress = this.recipientInput
          this.allowEdit = false
        } else {
          this.notification.warning({
            title: 'Invalid Input',
            content: 'Please use a valid address',
            duration: 3000,
          })
        }
      }
    },

    setUserAddr() {
      this.store.dispatch('setDestinationAddress', this.userAddress)
      this.close()
    },
  },
})
