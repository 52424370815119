
import { defineComponent, computed } from 'vue'
import { BigNumber } from 'ethers'
import { NIcon, NSkeleton, NTooltip, useNotification } from 'naive-ui'
import { AlertCircle } from '@vicons/ionicons5'
import { useStore } from '@/store'
import { networks } from '@/config'
import { toDecimals, truncateAddr } from '@/utils'
import { NetworkName } from '@/config/types'

import Breadcrumb from '@/components/Breadcrumb.vue'
import TransferSteps from '../Transfer.steps.vue'
import TransferPending from '../Transfer.pending.vue'
import Protocol from './Review.protocol.vue'
import ReviewDetail from './Review.detail.vue'
import ReviewSend from './Review.send.vue'
import { TransferStep } from '@/store/modules/userInput'

export default defineComponent({
  components: {
    Breadcrumb,
    NIcon,
    NSkeleton,
    NTooltip,
    AlertCircle,
    TransferSteps,
    Protocol,
    ReviewDetail,
    ReviewSend,
    TransferPending,
  },
  setup: () => {
    const store = useStore()
    const notification = useNotification()
    return {
      userInput: computed(() => store.state.userInput),
      walletAddress: computed(() => store.state.wallet.address),
      originGasFee: computed(() => {
        const { gasEst } = store.state.userInput
        if (!gasEst) return
        return toDecimals(gasEst, 9, 4)
      }),
      connextFee: computed(() => {
        const { decimals } = store.state.userInput.token
        const { quote } = store.state.connext
        return quote ? toDecimals(quote.totalFee, decimals, 6) : undefined
      }),
      quote: computed(() => store.state.connext.quote),
      sending: computed(() => store.state.sdk.sending),
      preparingSwap: computed(() => store.state.connext.preparingSwap),
      store,
      notification,
    }
  },
  data: () => ({
    protocol: 'connext',
  }),

  mounted() {
    this.selectConnext()
  },

  methods: {
    truncateAddr,
    nativeAssetSymbol(network: NetworkName) {
      if (network === 'avalanche') {
        return 'AVAX'
      }
      if (!networks[network].nativeToken) {
        return ''
      }
      return networks[network].nativeToken.symbol
    },
    receiveAssetSymbol() {
      const { token } = this.userInput
      if (token.nativeOnly) {
        return token.wrappedAsset
      }
      return token.symbol
    },
    async selectConnext() {
      this.protocol = 'connext'
      if (this.connextFee) return
      try {
        await this.store.dispatch('getTransferQuote')
      } catch (e) {
        if (this.protocol === 'connext') {
          this.notification.info({
            title: 'Unavailable',
            description:
              'Fast bridging with Connext is unavailable for this transaction. Please continue with Nomad.',
            duration: 5000,
          })
          console.log(e)
        }
        this.protocol = 'nomad'
      }
    },
    connextReceiveAmt() {
      const fees = Number.parseFloat(this.connextFee!)
      const total = this.userInput.sendAmount - fees
      return total.toFixed(6)
    },
    back() {
      if (this.sending || this.preparingSwap) return
      this.store.dispatch('resetTransferQuote')
      this.store.dispatch('setTransferStep', TransferStep.INPUT)
    },
  },
  computed: {
    connextAdditionalFee() {
      if (!this.quote) return
      const relayerFee = BigNumber.from(this.quote.metaTxRelayerFee)
      const routerFee = BigNumber.from(this.quote.routerFee)
      const total = relayerFee.add(routerFee)
      const formatted = toDecimals(total, 18, 4)
      return `${formatted} ${this.userInput.token.symbol}`
    },
    requiresManualProcessing(): boolean {
      const network = this.userInput.destinationNetwork
      if (!network) return false
      return !!networks[network].manualProcessing
    },
  },
})
