
import { defineComponent, h } from 'vue'
import {
  NAlert,
  NText,
  NSpin,
  NSteps,
  NStep,
  NIcon,
  NProgress,
  NCollapseTransition,
  useNotification,
} from 'naive-ui'
import { ChevronDown, AlertCircleOutline } from '@vicons/ionicons5'
import { BigNumber } from 'ethers'
import { useStore } from '@/store'
import {
  networks,
  BUFFER_CONFIRMATION_TIME_IN_MINUTES,
  PROCESS_TIME_IN_MINUTES,
} from '@/config'
import { minutesTilConfirmation } from '@/utils'
import { toNetworkName } from '@/utils'
import { NetworkName } from '@/config/types'
import NotificationError from '@/components/NotificationError.vue'

interface ComponentData {
  PROCESS_TIME_IN_MINUTES: number
  showStatus: boolean
  now: number
  markComplete: boolean
}

export default defineComponent({
  props: {
    status: {
      type: Number || undefined,
    },
    confirmAt: {
      type: BigNumber,
    },
    destinationNetwork: {
      type: String,
    },
  },
  components: {
    NAlert,
    NText,
    NSpin,
    NSteps,
    NStep,
    NIcon,
    NProgress,
    NCollapseTransition,
    ChevronDown,
    AlertCircleOutline,
  },
  data: () =>
    ({
      PROCESS_TIME_IN_MINUTES,
      showStatus: false,
      now: Date.now(),
      markComplete: false,
    } as ComponentData),
  setup: () => {
    const store = useStore()
    const notification = useNotification()

    return {
      store,
      notification,
    }
  },

  mounted() {
    setInterval(() => {
      this.now = Date.now()
    }, 10000)
  },
  methods: {
    async processTx() {
      try {
        const receipt = await this.store.dispatch('processTx', {
          origin: toNetworkName(this.$route.params.network as string),
          hash: this.$route.params.id,
        })
        if (receipt) {
          this.notification.success({
            title: 'Success',
            content: 'Transaction dispatched',
          })
          this.markComplete = true
        }
      } catch (e: unknown) {
        const errorMessage = (e as Error).message
        let description: string
        if (errorMessage) {
          switch (true) {
            case errorMessage.includes('!MessageStatus.None'):
              description = 'Transfer already completed'
              break
            case errorMessage.includes('!prove'):
              description =
                'Try again later. If this persists longer than 2 hours, reach out to us in Discord support'
              break
            case errorMessage.includes(
              'Unexpected token < in JSON at position 0'
            ):
              description = 'Not ready to claim. Proof not available'
              break
            default:
              description = 'Please reach out to us in Discord support channel'
          }
          this.notification.warning({
            title: 'Error Completing Transfer',
            content: () =>
              h(NotificationError, {
                text: description,
                error: e as Error,
              }),
          })
          throw e
        }
      }
    },
    getDisplayName(network: NetworkName) {
      if (!network) return
      return networks[network].displayName
    },
  },
  computed: {
    transferComplete(): boolean {
      return this.status === 4 || this.markComplete
    },
    showAlerts(): boolean {
      if (!this.status) return false
      return this.status >= 0 && this.status < 4
    },
    stepperStatus(): number {
      if (!this.status) return 1
      if (this.status === 0) {
        return 1
      } else if (this.status === 1) {
        return 2
      } else if (this.status === 2 || this.status === 3) {
        return 4
      } else if (this.transferComplete) {
        return 5
      }
      return 1
    },
    optimisticMinutes(): number | undefined {
      if (!this.destinationNetwork) return
      const { optimisticSeconds } = networks[this.destinationNetwork]
      return Math.ceil(optimisticSeconds / 60)
    },
    requiresManualProcessing(): boolean {
      if (!this.destinationNetwork) return false
      return !!networks[this.destinationNetwork].manualProcessing
    },
    minutesRemaining(): number | undefined {
      if (!this.optimisticMinutes || !this.now) return
      const bufferMinutes = BUFFER_CONFIRMATION_TIME_IN_MINUTES
      const processingTime = PROCESS_TIME_IN_MINUTES
      // if status doesn't exist
      if (!this.status && this.status !== 0) return
      if (this.status < 2) {
        return this.optimisticMinutes + bufferMinutes
      } else if (this.status === 2 && this.confirmAt) {
        const remaining = minutesTilConfirmation(this.confirmAt)
        if (!remaining) {
          return processingTime
        } else {
          return remaining + processingTime
        }
      }
      return bufferMinutes
    },
    confirmationProgress(): number {
      if (!this.optimisticMinutes || !this.now || !this.confirmAt) return 0
      const confirmationMinutesRemaining = minutesTilConfirmation(
        this.confirmAt
      )
      console.log(confirmationMinutesRemaining, ' minutes remaining')
      const fraction =
        (this.optimisticMinutes - confirmationMinutesRemaining) /
        this.optimisticMinutes
      return Math.floor(fraction * 100)
    },
    readyToManualProcess(): boolean {
      if (!this.confirmAt || !this.now || !this.destinationNetwork) return false
      // get timestamp in seconds
      const now = BigNumber.from(this.now).div(1000)
      // check if confirmAt time has passed
      // check if network is one that needs manual processing
      return now.gt(this.confirmAt) && this.requiresManualProcessing
    },
    statusAvailable(): boolean {
      if (this.status === undefined) {
        return false
      }
      return this.status >= 0
    },
  },
})
