import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center"
}
const _hoisted_2 = { class: "flex flex-col items-end leading-4" }
const _hoisted_3 = { class: "bg-black bg-opacity-50 rounded-lg p-3 ml-2" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.token)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, _toDisplayString(_ctx.amt), 1 /* TEXT */),
          _createElementVNode("div", null, _toDisplayString(_ctx.token.symbol), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.token.icon,
            class: "h-4"
          }, null, 8 /* PROPS */, _hoisted_4)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}