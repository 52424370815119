
import { defineComponent } from 'vue'
import { NText, NIcon } from 'naive-ui'
import { ChevronBack } from '@vicons/ionicons5'
import { RouterLink } from 'vue-router'

export default defineComponent({
  components: {
    NText,
    NIcon,
    ChevronBack,
    RouterLink,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '/',
    },
  },
})
