import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_back = _resolveComponent("chevron-back")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_text = _resolveComponent("n-text")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.href,
    class: "inline-flex items-center opacity-60 hover:underline p-1"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_icon, { size: "15" }, {
        default: _withCtx(() => [
          _createVNode(_component_chevron_back)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_n_text, { class: "uppercase font-medium" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"]))
}