
import { defineComponent, computed, ref } from 'vue'
import { ChevronBackOutline, ChevronForwardOutline } from '@vicons/ionicons5'
import { NText, NDivider, NIcon } from 'naive-ui'

import { useStore } from '@/store'
import { getNetworkByDomainID } from '@/utils'
import { getUserHistory } from '@/utils/nomadAPI'
import Transaction from './columns/transaction.vue'
import Amount from './columns/amount.vue'

type ComponentData = {
  history: Array<unknown>
  pollActiveTxs: number | null
}

export default defineComponent({
  components: {
    ChevronBackOutline,
    ChevronForwardOutline,
    NText,
    NDivider,
    NIcon,
    Transaction,
    Amount,
  },

  data() {
    return {
      history: [],
      pollActiveTxs: null,
    } as ComponentData
  },

  setup: () => {
    const store = useStore()

    return {
      store,
      size: 5,
      page: ref(1),
      pageCount: ref(1000),
      domains: store.getters.getDomains(),
      walletConnected: computed(() => store.state.wallet.connected),
      address: computed(() => store.state.wallet.address),
    }
  },

  mounted() {
    this.getHistory()
    this.pollActiveTxs = window.setInterval(this.getHistory, 15000)
  },

  unmounted() {
    this.clearPollActiveTxs()
  },

  methods: {
    async changePage(page: number) {
      if (page <= 0) return
      if (this.pageCount && page > this.pageCount) return
      if (page > this.page && this.history.length < this.size) return
      await this.getHistory(page)
    },

    async getHistory(page?: number) {
      if (!this.address) return

      const pageNum = page || this.page
      const history = await getUserHistory(
        this.domains,
        this.address,
        pageNum,
        this.size
      )
      if (!history.length) {
        this.pageCount = this.page
        return
      }
      this.history = history
      this.page = pageNum
    },

    clearPollActiveTxs() {
      if (this.pollActiveTxs) {
        window.clearInterval(this.pollActiveTxs)
        this.pollActiveTxs = null
      }
    },

    toTx(tx: any) {
      const originNetwork = getNetworkByDomainID(tx.origin).name
      this.$router.push(`/tx/nomad/${originNetwork}/${tx.dispatchTx}`)
    },
  },

  watch: {
    address(newAddress, oldAddress) {
      if (newAddress !== oldAddress) {
        this.history = []
        this.page = 1
        this.pageCount = 1000
        this.getHistory()
      }
    },
  },
})
