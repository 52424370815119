
import { defineComponent } from 'vue'
import { BigNumber } from 'ethers'
import {
  fromBytes32,
  getNetworkByDomainID,
  getTokenByTokenID,
  toDecimals,
} from '@/utils'
import { TokenMetadata } from '@/config/types'
import { IndexerTx } from '@/utils/nomadAPI'

interface ComponentData {
  amt: string | undefined
  token: TokenMetadata | undefined
}

export default defineComponent({
  props: {
    tx: {
      type: Object as () => IndexerTx,
      required: true,
    },
  },
  data() {
    return {
      amt: undefined,
      token: undefined,
    } as ComponentData
  },
  mounted() {
    this.getAmount()
  },
  methods: {
    getAmount() {
      const { amount, decimals, tokenDomain, tokenId } = this.tx
      const tokenIdentifier = {
        domain: getNetworkByDomainID(tokenDomain).name,
        id: fromBytes32(tokenId),
      }
      this.token = getTokenByTokenID(tokenIdentifier)!
      this.amt = toDecimals(BigNumber.from(amount), decimals, 6)
    },
  },
})
