import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "opacity-50 capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-row justify-between px py-2", { 'border-b border-opacity-5': _ctx.borderBottom }])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1 /* TEXT */),
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}