
import { defineComponent, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'

import { useStore } from '@/store'

import BgBlur from './Input.bgblur.vue'
import TransferAmount from './Input.amount.vue'
import TransferInputs from './Input.inputs.vue'
import TransferSteps from '../Transfer.steps.vue'
import NomadButton from '@/components/Button.vue'
import { useNotification } from 'naive-ui'
import { TransferStep } from '@/store/modules/userInput'
import { networks, tokens } from '@/config'

export default defineComponent({
  components: {
    BgBlur,
    TransferAmount,
    TransferInputs,
    TransferSteps,
    NomadButton,
  },

  setup: () => {
    const store = useStore()
    const notification = useNotification()

    // contains validation scope, collects validations from children components but does not emit up to parent
    const v$ = useVuelidate({
      $scope: 'bridge',
      $stopPropagation: true,
    })

    return {
      userInput: computed(() => store.state.userInput),
      store,
      notification,
      v$,
    }
  },

  mounted() {
    const { destination, token } = this.$route.query
    if (networks[destination as string]) {
      this.store.dispatch('setDestinationNetwork', destination)
    }
    const asset = tokens[token as string]
    if (asset) {
      this.store.dispatch('setToken', asset)
    }
  },

  methods: {
    async next() {
      const { token, originNetwork, destinationNetwork } = this.userInput
      // return if HBOT criteria is not met
      if (token.symbol === 'HBOT') {
        const networks = [originNetwork, destinationNetwork]
        const hasAvalanche = networks.some((n) => n === 'avalanche')
        const hasEthereum = networks.some((n) => n === 'ethereum')
        if (!hasAvalanche || !hasEthereum) {
          this.notification.warning({
            title: 'Action not supported',
            description:
              'HBOT token may only be sent between Avalanche and Ethereum',
            duration: 10000,
          })
          return
        }
      }
      // only allow HBOT to Avalanche
      if (destinationNetwork === 'avalanche' && token.symbol !== 'HBOT') {
        this.notification.warning({
          title: 'Action not supported',
          description:
            'HBOT is the only Nomad asset currently supported on Avalanche',
          duration: 10000,
        })
        return
      }
      const valid = await this.v$.$validate()
      if (valid) {
        this.store.dispatch('setTransferStep', TransferStep.REVIEW)
      }
    },
  },
})
