
import { defineComponent } from 'vue'
import { NCard, NPopselect, NTabs, NTabPane, useNotification } from 'naive-ui'
import NomadButton from '@/components/Button.vue'
import { connextScanURL } from '@/config'
import { generateNetworkOptions } from '@/utils'
export default defineComponent({
  components: {
    NCard,
    NPopselect,
    NTabs,
    NTabPane,
    NomadButton,
  },
  data() {
    return {
      panels: ['Connext', 'Nomad'],
      originNetwork: '',
      txHash: '',
      options: generateNetworkOptions(),
    }
  },
  setup() {
    const notification = useNotification()
    return {
      notification,
    }
  },
  methods: {
    goConnext() {
      if (this.txHash.length === 66) {
        // this.$router.push(`/tx/connext/${this.txHash}`)
        window.open(`${connextScanURL}tx/${this.txHash}`, '_blank')
      } else {
        this.notification.warning({
          title: 'Invalid Input',
          duration: 3000,
        })
      }
    },
    goNomad() {
      if (this.originNetwork && this.txHash.length === 66) {
        this.$router.push(`/tx/nomad/${this.originNetwork}/${this.txHash}`)
      } else {
        this.notification.warning({
          title: 'Invalid Input',
          duration: 3000,
        })
      }
    },
  },
})
