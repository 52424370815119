
import { defineComponent, computed } from 'vue'
import * as Sentry from '@sentry/browser'
import { useStore } from '@/store'

import { RouterView } from 'vue-router'
import Nav from '@/components/Layout/Nav.vue'
import Footer from '@/components/Layout/Footer.vue'
import CardAlert from '@/components/CardAlert.vue'
import TermsModal from '@/views/TermsModal.vue'
// import NetworkAlert from '@/components/NetworkAlert.vue'
import { getNetworkByDomainID } from '@/utils'
import analytics from '@/services/analytics'

export default defineComponent({
  components: {
    RouterView,
    Nav,
    Footer,
    CardAlert,
    TermsModal,
    // NetworkAlert,
  },

  data: () => ({
    failedHomes: new Set(),
  }),

  setup: () => {
    const store = useStore()

    return {
      originNetwork: computed(() => store.state.userInput.originNetwork),
      destinationNetwork: computed(
        () => store.state.userInput.destinationNetwork
      ),
      address: computed(() => store.state.wallet.address),
    }
  },

  async mounted() {
    if (this.address) {
      Sentry.setUser({ id: this.address })
    }
    const store = useStore()

    // instantiate Nomad & Connext
    await store.dispatch('instantiateNomad')

    // set failedHomes
    setInterval(() => {
      this.failedHomes = store.getters.blacklist()
    }, 3000)
  },

  methods: { getNetworkByDomainID },

  watch: {
    address(newAddr) {
      if (newAddr) {
        Sentry.setUser({ id: newAddr })
      }
    },
    $route(to) {
      // track page changes
      analytics.page(to.name)
    },
  },
})
