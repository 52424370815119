import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full max-w-xl relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_transfer = _resolveComponent("transfer")!
  const _component_connext_active = _resolveComponent("connext-active")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" transfer card "),
    _createVNode(_component_transfer),
    _createCommentVNode(" active connext transfers table "),
    _createVNode(_component_connext_active)
  ]))
}