
import { defineComponent, computed } from 'vue'
import { truncateAddr } from '@/utils'
import { NText, NIcon, NTooltip, NDivider } from 'naive-ui'
import { ChevronDown } from '@vicons/ionicons5'
import NomadButton from '@/components/Button.vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    NText,
    NIcon,
    NTooltip,
    NDivider,
    ChevronDown,
    NomadButton,
  },
  data: () => ({
    buttonDisabled: false,
  }),
  setup: () => {
    const store = useStore()
    const route = useRoute()
    return {
      walletConnected: computed(() => store.state.wallet.connected),
      showButton: computed(() =>
        ['Bridge', 'NomadTransaction'].includes(route.name as string)
      ),
      showSearch: computed(() => route.name !== 'Transaction'),
      store,
    }
  },
  methods: {
    async handleConnect() {
      this.buttonDisabled = true
      try {
        await this.store.dispatch('connectWallet')
      } catch (error) {
        // TODO: determine how we want to handle wallet connect errors
        console.log('error', error)
      } finally {
        this.buttonDisabled = false
      }
    },
  },
  computed: {
    truncatedAddress(): string {
      const { address, connected } = this.store.state.wallet
      return connected ? truncateAddr(address) : ''
    },
  },
})
