
import { defineComponent, h } from 'vue'
import { useNotification } from 'naive-ui'
import { connextScanURL } from '@/config'
import { useStore } from '@/store'
import NomadButton from '@/components/Button.vue'
import NotificationLink from '@/components/NotificationLink.vue'

export default defineComponent({
  props: {
    txAction: {
      type: Object,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    expired: {
      type: Boolean,
    },
  },
  data() {
    return {
      disabled: false,
    }
  },
  setup: () => {
    const store = useStore()
    const notification = useNotification()

    return {
      store,
      notification,
    }
  },
  methods: {
    async claim() {
      console.log('claim', this.txAction)
      this.disabled = true
      await this.store.dispatch('finishTransfer', this.txAction)
      this.disabled = false
    },
    async cancel() {
      console.log('cancel expired connext tx', this.txAction)
      this.disabled = true
      try {
        await this.store.dispatch('cancelTransfer', this.txAction)
        this.notification.info({
          title: 'Transfer cancelled',
        })
      } catch (e) {
        this.notification.warning({
          title: 'Error Cancelling Transfer',
          content: () =>
            h(NotificationLink, {
              text: 'Please visit Connextscan to cancel ',
              linkText: 'your transaction',
              link: this.explorerLink,
            }),
        })
        throw e
      }
      this.disabled = false
    },
  },
  components: {
    NomadButton,
  },
  computed: {
    ready() {
      return this.status === 'ReceiverTransactionPrepared'
    },
    explorerLink() {
      return `${connextScanURL}tx/${this.hash}`
    },
  },
})
