
import { defineComponent, computed } from 'vue'
import { NModal, NCard, NCheckbox } from 'naive-ui'
import { useStore } from '@/store'
import { termsAPI } from '@/config'
import NomadButton from '@/components/Button.vue'
import Terms from '@/views/TermsOfUse.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    NModal,
    NCard,
    NCheckbox,
    NomadButton,
    Terms,
  },

  setup: () => {
    const store = useStore()
    const route = useRoute()
    return {
      walletAddress: computed(() => store.state.wallet.address),
      hideModal: computed(() =>
        ['TermsOfUse', 'PrivacyPolicy'].includes(route.name as string)
      ),
      store,
    }
  },

  data: () => ({
    showTermsModal: false,
    readToS: false,
  }),

  mounted() {
    this.checkTerms(this.walletAddress)
  },

  methods: {
    async agree() {
      try {
        const response = await fetch(
          `${termsAPI}api/agree/${this.walletAddress}`,
          {
            method: 'POST',
          }
        )
        console.log(response)
        this.checkTerms(this.walletAddress)
      } catch (e) {
        // if db is down, allow user to proceed after agreeing
        this.showTermsModal = false
        throw new Error(`Database for Terms & Agreements is down: \n${e}`)
      }
    },
    async checkTerms(addr: string) {
      if (!addr) {
        return (this.showTermsModal = false)
      }

      try {
        const response = await fetch(`${termsAPI}api/agreement/${addr}`)
        if (response.status === 200) {
          console.log('user agreed')
          return (this.showTermsModal = false)
        } else if (response.status === 404) {
          return (this.showTermsModal = true)
        }
      } catch (e) {
        // if db is down, show terms by default
        this.showTermsModal = true
        throw new Error(`Database for Terms & Agreements is down: \n${e}`)
      }
    },
  },

  watch: {
    walletAddress(newAddr) {
      this.checkTerms(newAddr)
    },
  },
})
