
import { defineComponent } from 'vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import TxDetails from './Details.vue'

export default defineComponent({
  components: {
    Breadcrumb,
    TxDetails,
  },
})
