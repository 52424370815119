
import { defineComponent, computed } from 'vue'
import { NModal, NCard, NText, NButton, NIcon } from 'naive-ui'
import { RepeatOutline } from '@vicons/ionicons5'
import NomadButton from '@/components/Button.vue'
import Search from '@/components/Search.vue'

import { networks, tokens } from '@/config'
import { TokenMetadata } from '@/config/types'
import { useStore } from '@/store'

export default defineComponent({
  emits: ['selectToken', 'hide'],

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    NModal,
    NCard,
    NText,
    NButton,
    NIcon,
    RepeatOutline,
    NomadButton,
    Search,
  },

  setup: () => {
    const store = useStore()

    return {
      network: computed(() => networks[store.state.userInput.originNetwork]),
      tokens: Object.values(tokens),
      store,
    }
  },

  data: () => ({
    searchText: '',
  }),

  methods: {
    select(token: TokenMetadata) {
      if (this.shouldSwitchToNative(token)) return
      this.$emit('selectToken', token)
      this.$emit('hide')
    },

    shouldSwitchToNative(token: TokenMetadata): boolean {
      if (!this.network || !token.nativeOnly) return false
      return token.nativeNetwork !== this.network.name
    },

    async switchAndSelect(token: TokenMetadata) {
      try {
        await this.store.dispatch('switchNetwork', token.nativeNetwork)
        this.select(token)
      } catch (e: unknown) {
        this.$emit('hide')
      }
    },

    updateSearch(text: string) {
      this.searchText = text
    },
  },

  computed: {
    tokensFilter() {
      if (this.store.state.userInput.destinationNetwork === 'avalanche') {
        return this.tokens.filter((t) => t.symbol === 'HBOT')
      }
      if (this.searchText.length) {
        return this.searchMatch
      }
      return this.tokens
    },
    searchMatch(): TokenMetadata[] {
      return this.tokens.filter((t) => {
        const search = this.searchText.toLowerCase()
        const symbol = t.key.toLowerCase()
        const name = t.name.toLowerCase()
        if (symbol.includes(search)) return true
        if (name.includes(search)) return true
        if (t.tokenIdentifier) {
          const address = (t.tokenIdentifier.id as string).toLowerCase()
          if (address === search) return true
        }
        return false
      })
    },
  },
})
