import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-391a04de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row justify-end" }
const _hoisted_2 = {
  key: 0,
  class: "bg-white bg-opacity-50 w-2 h-0.5 m-0.5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2], (step) => {
      return _createElementVNode("div", {
        key: step,
        class: "flex flex-row items-center"
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["flex justify-center items-center w-5 h-5 rounded-full bg-white bg-opacity-30 text-white text-xs", { active: step === _ctx.current }])
        }, _toDisplayString(step), 3 /* TEXT, CLASS */),
        (step != 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("v-if", true)
      ])
    }), 64 /* STABLE_FRAGMENT */))
  ]))
}